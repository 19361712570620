<template>
  <b-card class="provider-agents">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="agentsGrid"
          :button-label="$t('Add Agents')"
          :columns="agentsColumn"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/provider/agents/all'"
          @add-new-info="showAddProviderModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showAgentTimings"
      :no-close-on-backdrop="true"
      :title="'Agent Timing'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Update Agent Timing')"
      @ok="updateAgentTiming"
    >
      <agent-timings :agent-timings="agentTimings" />
      <modal-footer
        :show-cancel="true"
        @hidden="showAgentTimings = false"
        @ok="updateAgentTiming"
      />
    </b-modal>
    <b-modal
      v-model="showProviderEdit"
      :no-close-on-backdrop="true"
      :title="editProviderInfo && editProviderInfo.id ? 'Edit Provider' : 'Add Provider'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Update Agent')"
      @hidden="hideProviderAgents"
      @ok="updateAgents"
    >
      <b-row v-if="editProviderInfo">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('First Name')">
            <b-form-input
              v-model="editProviderInfo.first_name"
              :class="{ 'is-invalid': !editProviderInfo.first_name && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Last Name')">
            <b-form-input
              v-model="editProviderInfo.last_name"
              :class="{ 'is-invalid': !editProviderInfo.last_name && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Location')">
            <GmapAutocomplete
              ref="autoCompleteRef"
              :value="editProviderInfo.location"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': !editProviderInfo.location && !editProviderInfo.id && isDataSubmitted }"
              @input="editProviderInfo.location = $event.target.value"
              @place_changed="setPlace"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Email')">
            <b-form-input
              v-model="editProviderInfo.email"
              type="email"
              :class="{ 'is-invalid': !editProviderInfo.email && isDataSubmitted }"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <password-input
            :input-field="editProviderInfo.password"
            :label="$t('Enter password')"
            :listener="'password'"
            @update-changes="updateChanges"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <password-input
            :input-field="editProviderInfo.password_confirmation"
            :label="$t('Enter confirm password')"
            :listener="'password_confirmation'"
            @update-changes="updateChanges"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          style="z-index: 999999"
        >
          <b-form-group :label="$t('Phone Number')">
            <VuePhoneNumberInput
              ref="countryCodeRef"
              v-model="editProviderInfo.mobile"
              placeholder="+91 0000000000"
              @update="onPhoneNumberUpdate"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="no-margin-radio"
        >
          <b-form-group :label="$t('Select Gender')">
            <b-form-radio-group
              v-model="editProviderInfo.gender"
              :options="genderOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Select Country')">
            <b-form-select
              v-model="editProviderInfo.country_id"
              disabled
              :options="countryOptions"
              @change="onCountryChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Select City')">
            <b-form-select
              v-model="editProviderInfo.city_id"
              disabled
              :options="cityOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="editProviderInfo.picture"
              :class="{ 'is-invalid': !editProviderInfo.picture && isDataSubmitted }"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="updateAgents"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BFormRadioGroup, BFormSelect, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import PasswordInput from '../users/shared/PasswordInput.vue'
import ImageInput from '../users/shared/ImageInput.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { useLoginUi } from '../auth/useAuth'
import AgentTimings from './shared/AgentTimings.vue'

export default {
  components: {
    BRow,
    ModalFooter,
    BModal,
    BCol,
    GoodDataTable,
    BCard,
    BFormGroup,
    BFormInput,
    PasswordInput,
    VuePhoneNumberInput,
    BFormRadioGroup,
    BFormSelect,
    ImageInput,
    AgentTimings,
  },
  data() {
    return {
      isDataSubmitted: false,
      genderOptions: [
        { text: 'Male', value: 'MALE', disabled: false },
        { text: 'Female', value: 'FEMALE', disabled: false },
      ],
      agentsColumn: [
        {
          label: 'Image',
          field: 'picture',
          align: 'center',
          preventExport: true,
          useHtml: true,
          excelHtml: props => props.picture,
          renderer: props => `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />`,
        },
        {
          label: 'First Name',
          field: 'first_name',
        },
        {
          label: 'Last Name',
          field: 'last_name',
        },
        {
          label: 'Wallet Balance',
          field: 'wallet_balance',
          useRenderer: true,
          renderer: props => `${props.wallet_balance} ${props.currency_symbol}`,
        },
        {
          label: 'Status',
          field: 'activation_status',
          rField: 'activation_status_text',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            // eslint-disable-next-line eqeqeq
            if (props.activation_status == 1 && props.status === 'APPROVED') {
              return 'Active'
            }
            return 'Inactive'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editAgent(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.updateAgentsStatus(props)
              },
            },
            {
              isIcon: true,
              // eslint-disable-next-line global-require
              iconHtml: require('@/assets/images/app-icons/Timing.png'),
              text: 'Timings',
              action: props => {
                this.showAgentTimings = true
                this.editTimings(props)
              },
            },
          ],
        },
      ],
      countryOptions: [],
      cityOptions: [],
      countryList: [],
      walletList: null,
      agentTimings: {
        timings: [],
      },
      editProviderInfo: null,
      showProviderEdit: false,
      showAgentTimings: false,
    }
  },
  mounted() {
    const { userCountries } = useLoginUi()
    userCountries({
      salt_key: window.SuperApp.getters.saltKey(),
    })
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.countryList = data.responseData
          const country = data.responseData.map(x => ({
            text: x.country_name,
            value: x.id,
          }))
          this.countryOptions.push({
            text: 'Select Country',
            value: '',
          })
          country.forEach(element => {
            this.countryOptions.push({
              text: element.text,
              value: element.value,
            })
          })
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    updateAgentTiming() {
      const formData = new FormData()
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      days.forEach((x, i) => {
        if (this.agentTimings.timings[i]) {
          if (this.agentTimings.timings[i].agent_enabled) {
            formData.append(`day[${x}]`, this.agentTimings.timings[i].day)
          }
          formData.append(`hours_opening[${x}]`, this.agentTimings.timings[i].start_time)
          formData.append(`hours_closing[${x}]`, this.agentTimings.timings[i].end_time)
        }
      })
      formData.append('_method', 'PATCH')
      const { saveProviderTiming } = useProviderUi()
      showLoader()
      saveProviderTiming({
        id: this.agentTimings.agent_id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Updated successfully')
            this.showAgentTimings = false
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    editTimings(props) {
      const { getProviderTiming } = useProviderUi()
      showLoader()
      getProviderTiming(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const response = data.responseData
            const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
            this.agentTimings = {
              agent_id: props.id,
              // everyday: response[0] && response[0].day === 'ALL',
              timings: [],
            }
            if (this.agentTimings.everyday) {
              this.agentTimings = {
                ...this.agentTimings,
                timings: [
                  {
                    day: 'ALL',
                    agent_enabled: true,
                    end_time: this.getStoreTIme(response, 'ALL', 'end_time'),
                    start_time: this.getStoreTIme(response, 'ALL', 'start_time'),
                    open_all_time: response && response[0] && response[0].open_all_time === 1,
                  },
                ],
              }
            } else {
              this.agentTimings = {
                ...this.agentTimings,
                timings: dayKeys.map(x => ({
                  day: x,
                  end_time: this.getStoreTIme(response, x, 'end_time'),
                  start_time: this.getStoreTIme(response, x, 'start_time'),
                  agent_enabled: this.isTimeEnabled(response, 'ALL') ? true : this.isTimeEnabled(response, x),
                })),
              }
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreTIme(timings, key, tag) {
      return timings && timings.find(x => x.day === key) ? timings.find(x => x.day === key)[tag] : '00:00'
    },
    isTimeEnabled(timings, key) {
      return timings && Boolean(timings.find(x => x.day === key))
    },
    setPlace(place) {
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.editProviderInfo.latitude = marker.lat
      this.editProviderInfo.longitude = marker.lng
    },
    showAddProviderModal() {
      this.showProviderEdit = true
      setTimeout(() => {
        const storeCountryCode = window.SuperApp.getters.userInfo().country_code
        if (storeCountryCode) {
          window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, storeCountryCode, '')
        }
      }, 250)
      this.editProviderInfo = {
        id: 0,
        first_name: '',
        country_code: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        gender: 'MALE',
        country_id: '0',
        picture: '',
        city_id: '',
        mobile: '',
        latitude: '',
        longitude: '',
      }
      this.isDataSubmitted = false
      this.setCountryCity()
    },
    setCountryCity() {
      const storeInfo = window.SuperApp.getters.userInfo()
      this.editProviderInfo.country_id = storeInfo.country_id
      this.onCountryChange()
      this.editProviderInfo.city_id = storeInfo.city_id
    },
    inputFile(payload) {
      if (this.editProviderInfo) this.editProviderInfo.picture = payload
    },
    onCountryChange() {
      this.cityOptions = []
      let cityList = []
      const isCountryExist = this.countryList.find(x => x.id === this.editProviderInfo.country_id)
      if (isCountryExist) {
        cityList = isCountryExist.city.map(x => ({
          text: x.city_name,
          value: x.id,
        }))
      }
      if (!cityList.find(x => x.value === this.editProviderInfo.city_id)) this.editProviderInfo.city_id = ''
      this.cityOptions.push({
        text: 'Select City',
        value: '',
      })
      cityList.forEach(element => {
        this.cityOptions.push({
          text: element.text,
          value: element.value,
        })
      })
    },
    onPhoneNumberUpdate(payload) {
      if (this.editProviderInfo) this.editProviderInfo.mobile = payload.nationalNumber
      if (this.editProviderInfo && payload && payload.countryCallingCode) this.editProviderInfo.country_code = payload.countryCallingCode
    },
    updateChanges(props) {
      this.editProviderInfo[props.key] = props.value
    },
    hideProviderAgents() {
      // this.editProviderInfo = null
      this.showProviderEdit = false
    },
    updateAgentsStatus(props) {
      this.$swal({
        title: this.$t('You want to change the status?'),
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const providerObj = {
            id: props.id,
            // eslint-disable-next-line eqeqeq
            status: props.activation_status == 1 && props.status == 'APPROVED' ? 'Inactive' : 'active',
          }
          const { upateProviderAgentStatus } = useProviderUi()
          showLoader()
          upateProviderAgentStatus(providerObj)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.$refs.agentsGrid.loadItems()
                showSuccessNotification(this, data.message)
              } else {
                showDangerNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    editAgent(props) {
      showLoader()
      const { getProviderAgentById } = useProviderUi()
      getProviderAgentById(props.id)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            const payloadInfo = window.SuperApp.actions.cloneDeep(data.responseData)
            this.editProviderInfo = data.responseData
            this.editProviderInfo.picture = window.SuperApp.helper.image(this.editProviderInfo.picture)
            this.showProviderEdit = true
            setTimeout(() => {
              if (this.editProviderInfo.country_code) {
                window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, payloadInfo.country_code, payloadInfo.mobile)
              }
            }, 500)
            this.setCountryCity()
            const self = this
            if (this.editProviderInfo.latitude && this.editProviderInfo.longitude) {
              setTimeout(() => {
                window.SuperApp.helper.getFormattedAddress(`${this.editProviderInfo.latitude},${this.editProviderInfo.longitude}`).then(res => {
                  if (self.$refs.autoCompleteRef && self.$refs.autoCompleteRef.$el) {
                    self.$refs.autoCompleteRef.$el.value = ''
                  }
                  self.editProviderInfo.location = window.SuperApp.helper.parsedAddress(res)
                  if (self.$refs.autoCompleteRef && self.$refs.autoCompleteRef.$el) {
                    self.$refs.autoCompleteRef.$el.value = self.editProviderInfo.location
                  }
                })
              }, 500)
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getAgentForm() {
      const formData = new FormData()
      const formArray = ['first_name', 'id', 'country_code', 'last_name', 'email', 'gender', 'country_id', 'city_id', 'latitude', 'longitude']
      formArray.forEach(item => {
        formData.append(item, this.editProviderInfo[item])
      })
      if (this.editProviderInfo.password) {
        formData.append('password', this.editProviderInfo.password)
      }
      if (this.editProviderInfo.password_confirmation) {
        formData.append('password_confirmation', this.editProviderInfo.password_confirmation)
      }
      if (this.editProviderInfo.mobile) formData.append('mobile', this.editProviderInfo.mobile.replace(/[^0-9]/g, ''))
      if (this.editProviderInfo.picture && window.SuperApp.helper.withBaseUrl(this.editProviderInfo.picture)) formData.append('picture', this.editProviderInfo.picture)
      formData.append('parent_type', 'PROVIDER')
      return formData
    },
    deleteAgents(props) {
      this.$swal({
        title: this.$t('You want to delete this agent?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const { deleteAgentsProfile } = useProviderUi()
          showLoader()
          deleteAgentsProfile(props.id)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.$refs.agentsGrid.loadItems()
                showSuccessNotification(this, data.message)
              } else {
                showDangerNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    addAgents() {
      const formData = this.getAgentForm()
      formData.delete('id')
      this.showProviderEdit = false
      const { addAgentsProfile } = useProviderUi()
      showLoader()
      addAgentsProfile(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.agentsGrid.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
            this.showProviderEdit = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showProviderEdit = true
          showErrorNotification(this, error)
        })
    },
    updateAgents() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showProviderEdit = false
        this.isDataSubmitted = false
        if (!this.editProviderInfo.id) {
          this.addAgents()
          return
        }
        const formData = this.getAgentForm()
        formData.append('_method', 'PATCH')
        const { updateAgentsProfile } = useProviderUi()
        showLoader()
        updateAgentsProfile({
          id: this.editProviderInfo.id,
          data: formData,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.$refs.agentsGrid.loadItems()
              showSuccessNotification(this, 'Agents updated successfully')
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}
</style>

<style>
@media only screen and (max-width: 767px) {
  .provider-agents .grid-main-action-button {
    display: block !important;
    text-align: right;
    margin-bottom: 10px;
  }
}
</style>
